import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Collapse } from 'react-collapse';

const AlbumsPage = ({ data }) => {
  let windowWidth
  if(typeof window !== "undefined"){
  windowWidth = window.innerWidth;
}
  const [isSongListOpen, setIsSongListOpen] = React.useState(windowWidth >= 640 ? true : false);
  const renderOptions = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  const album = data.contentfulAlbum

  return (
    <Layout pageTitle="Albums">
      <div className='px-6'>

        <div className='justify-center gap-4 text-center sm:flex-none md:flex md:mt-10'>
          <div className='w-full md:w-1/3'>
            <GatsbyImage image={album.cover.gatsbyImageData} alt={album.title} />
          </div>

          <div className='text-left w-full md:w-2/3 ml-6'>
            <h2 className='text-3xl font-bold mt-10 md:mt-0'>{album.title}</h2>
            <div>
              {album.credits && <div className='mt-4'>{renderRichText(album.credits, renderOptions)}</div>}
              {album.description && <div className='mt-6'>{renderRichText(album.description, renderOptions)}</div>}
              {album.video && <div className='mt-6'><iframe width="560" height="315" src={album.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>}
            </div>
          </div>
        </div>

        <button onClick={() => setIsSongListOpen(!isSongListOpen)} className={`${windowWidth >= 640 ? 'hidden' : 'flex'} mt-10`}>
          <h2>Songlist &nbsp;</h2>
          {isSongListOpen ? <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m-15 0l15 15" />
          </svg> :
            <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          }
        </button>

        <Collapse isOpened={isSongListOpen}>
          <div className='flex-wrap flex justify-center mt-14 text-center'>
            {album.songs.length > 0 && album.songs.map((song) => (
              <a href={`#${song.title}`} key={song.title}><button className='m-3 border-slate-600 border-b-2 text-xs hover:border-white md:min-h-[50px] min-w-[70px] min-h-[30px] text-white'>{song.title}</button></a>
            ))}
          </div>
        </Collapse>

        <div>
          {album.songs.length > 0 && album.songs.map((song) => (
            <div className='mt-14' id={song.title} key={song.title}>
              <p className='mb-2 font-cursive text-3xl'>{song.title}</p>
              {song.songVideo && <div className='my-4'><iframe width="560" height="315" src={song.songVideo} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>}
              <div className='lyricsMarkdown mb-16'>
                {renderRichText(song.testLyrics, renderOptions)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout >
  )
}

export const albumquery = graphql`
query($slug:String!) {
    contentfulAlbum(slug:{eq:$slug}) {
            id
          title
          slug
          credits {
            raw
          }
          description {
            raw
          }
          cover {
            gatsbyImageData(width:400)
          }
          songs {
            title
            testLyrics {
              raw
            }
            songVideo
          }
          video
        }
      }
    `

export default AlbumsPage